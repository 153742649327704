/* Code taken from Materialize */

/* Hide thumbnails from slider */
.thumbs.animated {
    display: none;
}

.carousel .slide .slider-content {
    position: absolute;
    bottom: 100px;
    left: 53%;
    margin-left: -45%;
    color: #fff;
    text-align: left;
}

.carousel .slide .slider-content h1 {
  font-size: 50px;
  /*text-shadow: 2px 4px 3px black;*/
}

.carousel .slide .slider-content p {
  font-size: 24px;
}

.carousel .slide .slider-content .content-width {
  width: 50%;
}

.carousel-status {
  display: none;
}

/* Mobile Slider */

.carousel .slide .slider-mobile {
  position: absolute;
  bottom: 100px;
  left: 53%;
  margin-left: -45%;
  color: #fff;
  text-align: left;
}

.carousel .slide .slider-mobile h1 {
font-size: 25px;
/*text-shadow: 2px 4px 3px black;*/
}

.carousel .slide .slider-mobile p {
font-size: 12px;
}

@media only screen and (max-width: 414px) {
  
  .carousel .slide .slider-content {
    text-align: center;
    margin-left: -30%;
    bottom: 30px;
  }

  
}  

.indigo-text.text-lighten-3 {
  color: #9fa8da !important;
}

.indigo.darken-3 {
  background-color: #283593 !important;
}

.indigo.darken-4 {
  background-color: #1a237e !important;
}

.indigo-text.text-darken-4 {
  color: #1a237e !important;
}

.green {
  background-color: #4CAF50 !important;
}


.green.lighten-2 {
  background-color: #81C784 !important;
}

.green-text.text-lighten-2 {
  color: #81C784 !important;
}

.green.lighten-1 {
  background-color: #66BB6A !important;
}

.green-text.text-lighten-1 {
  color: #66BB6A !important;
}

.green.darken-1 {
  background-color: #43A047 !important;
}

.green-text.text-darken-1 {
  color: #43A047 !important;
}

.green.darken-2 {
  background-color: #388E3C !important;
}

.green-text.text-darken-2 {
  color: #388E3C !important;
}

.green.darken-3 {
  background-color: #2E7D32 !important;
}

.green-text.text-darken-3 {
  color: #2E7D32 !important;
}

.green.darken-4 {
  background-color: #1B5E20 !important;
}

.green-text.text-darken-4 {
  color: #1B5E20 !important;
}

.green.accent-1 {
  background-color: #B9F6CA !important;
}

.green-text.text-accent-1 {
  color: #B9F6CA !important;
}

.green.accent-2 {
  background-color: #69F0AE !important;
}

.green-text.text-accent-2 {
  color: #69F0AE !important;
}

.green.accent-3 {
  background-color: #00E676 !important;
}

.green-text.text-accent-3 {
  color: #00E676 !important;
}

.green.accent-4 {
  background-color: #00C853 !important;
}

.green-text.text-accent-4 {
  color: #00C853 !important;
}

.grey {
  background-color: #9e9e9e !important;
}

.grey-text {
  color: #9e9e9e !important;
}

.grey.lighten-5 {
  background-color: #fafafa !important;
}

.grey-text.text-lighten-5 {
  color: #fafafa !important;
}

.grey.lighten-4 {
  background-color: #f5f5f5 !important;
}

.grey-text.text-lighten-4 {
  color: #f5f5f5 !important;
}

.grey.lighten-3 {
  background-color: #eeeeee !important;
}

.grey-text.text-lighten-3 {
  color: #eeeeee !important;
}

.grey.lighten-2 {
  background-color: #e0e0e0 !important;
}

.grey-text.text-lighten-2 {
  color: #e0e0e0 !important;
}

.grey.lighten-1 {
  background-color: #bdbdbd !important;
}

.grey-text.text-lighten-1 {
  color: #bdbdbd !important;
}

.grey.darken-1 {
  background-color: #757575 !important;
}

.grey-text.text-darken-1 {
  color: #757575 !important;
}

.grey.darken-2 {
  background-color: #616161 !important;
}

.grey-text.text-darken-2 {
  color: #616161 !important;
}

.grey.darken-3 {
  background-color: #424242 !important;
}

.grey-text.text-darken-3 {
  color: #424242 !important;
}

.grey.darken-4 {
  background-color: #212121 !important;
}

.grey-text.text-darken-4 {
  color: #212121 !important;
}

.black {
  background-color: #000000 !important;
}

.black-text {
  color: #000000 !important;
}

.white {
  background-color: #FFFFFF !important;
}

.white-text {
  color: #FFFFFF !important;
}

body {
  margin: 0;
}


ul:not(.browser-default) {
  padding-left: 0;
  list-style-type: none;
}

ul:not(.browser-default) > li {
  list-style-type: none;
}

ul.staggered-list li {
  opacity: 0;
}

.fade-in {
  opacity: 0;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
}

@media only screen and (max-width: 600px) {
  .hide-on-small-only, .hide-on-small-and-down {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .hide-on-med-and-down {
    display: none !important;
  }
}

@media only screen and (min-width: 601px) {
  .hide-on-med-and-up {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
  .hide-on-med-only {
    display: none !important;
  }
}

@media only screen and (min-width: 993px) {
  .hide-on-large-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1201px) {
  .hide-on-extra-large-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1201px) {
  .show-on-extra-large {
    display: block !important;
  }
}

@media only screen and (min-width: 993px) {
  .show-on-large {
    display: block !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
  .show-on-medium {
    display: block !important;
  }
}

@media only screen and (max-width: 600px) {
  .show-on-small {
    display: block !important;
  }
}

@media only screen and (min-width: 601px) {
  .show-on-medium-and-up {
    display: block !important;
  }
}

@media only screen and (max-width: 992px) {
  .show-on-medium-and-down {
    display: block !important;
  }
}

@media only screen and (max-width: 600px) {
  .center-on-small-only {
    text-align: center;
  }
}

.page-footer {
  padding-top: 20px;
  color: #fff;
  background-color: #ee6e73;
}

.page-footer .footer-copyright {
  overflow: hidden;
  min-height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 0px;
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(51, 51, 51, 0.08);
}


.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.center, .center-align {
  text-align: center;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.no-select, input[type=range],
input[type=range] + .thumb {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.circle {
  border-radius: 50%;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-padding {
  padding: 0 !important;
}

span.badge {
  min-width: 3rem;
  padding: 0 6px;
  margin-left: 14px;
  text-align: center;
  font-size: 1rem;
  line-height: 22px;
  height: 22px;
  color: #757575;
  float: right;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

span.badge.new {
  font-weight: 300;
  font-size: 0.8rem;
  color: #fff;
  background-color: #26a69a;
  border-radius: 2px;
}

span.badge.new:after {
  content: " new";
}

span.badge[data-badge-caption]::after {
  content: " " attr(data-badge-caption);
}

nav ul a span.badge {
  display: inline-block;
  float: none;
  margin-left: 4px;
  line-height: 22px;
  height: 22px;
  -webkit-font-smoothing: auto;
}

.collection-item span.badge {
  margin-top: calc(0.75rem - 11px);
}

.collapsible span.badge {
  margin-left: auto;
}

.sidenav span.badge {
  margin-top: calc(24px - 11px);
}

table span.badge {
  display: inline-block;
  float: none;
  margin-left: auto;
}

/* This is needed for some mobile phones to display the Google Icon font properly */
.material-icons {
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'liga';
     -moz-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}

.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}

@media only screen and (min-width: 601px) {
  .container {
    width: 85%;
  }
}

@media only screen and (min-width: 993px) {
  .container {
    width: 70%;
  }
}

.col .row {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.section.no-pad {
  padding: 0;
}

.section.no-pad-bot {
  padding-bottom: 0;
}

.section.no-pad-top {
  padding-top: 0;
}

.row {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.row .col {
  float: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 0.75rem;
  min-height: 1px;
}

.row .col[class*="push-"], .row .col[class*="pull-"] {
  position: relative;
}

.row .col.s1 {
  width: 8.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s2 {
  width: 16.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s3 {
  width: 25%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s4 {
  width: 33.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s5 {
  width: 41.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s6 {
  width: 50%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s7 {
  width: 58.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s8 {
  width: 66.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s9 {
  width: 75%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s10 {
  width: 83.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s11 {
  width: 91.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s12 {
  width: 100%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.offset-s1 {
  margin-left: 8.3333333333%;
}

.row .col.pull-s1 {
  right: 8.3333333333%;
}

.row .col.push-s1 {
  left: 8.3333333333%;
}

.row .col.offset-s2 {
  margin-left: 16.6666666667%;
}

.row .col.pull-s2 {
  right: 16.6666666667%;
}

.row .col.push-s2 {
  left: 16.6666666667%;
}

.row .col.offset-s3 {
  margin-left: 25%;
}

.row .col.pull-s3 {
  right: 25%;
}

.row .col.push-s3 {
  left: 25%;
}

.row .col.offset-s4 {
  margin-left: 33.3333333333%;
}

.row .col.pull-s4 {
  right: 33.3333333333%;
}

.row .col.push-s4 {
  left: 33.3333333333%;
}

.row .col.offset-s5 {
  margin-left: 41.6666666667%;
}

.row .col.pull-s5 {
  right: 41.6666666667%;
}

.row .col.push-s5 {
  left: 41.6666666667%;
}

.row .col.offset-s6 {
  margin-left: 50%;
}

.row .col.pull-s6 {
  right: 50%;
}

.row .col.push-s6 {
  left: 50%;
}

.row .col.offset-s7 {
  margin-left: 58.3333333333%;
}

.row .col.pull-s7 {
  right: 58.3333333333%;
}

.row .col.push-s7 {
  left: 58.3333333333%;
}

.row .col.offset-s8 {
  margin-left: 66.6666666667%;
}

.row .col.pull-s8 {
  right: 66.6666666667%;
}

.row .col.push-s8 {
  left: 66.6666666667%;
}

.row .col.offset-s9 {
  margin-left: 75%;
}

.row .col.pull-s9 {
  right: 75%;
}

.row .col.push-s9 {
  left: 75%;
}

.row .col.offset-s10 {
  margin-left: 83.3333333333%;
}

.row .col.pull-s10 {
  right: 83.3333333333%;
}

.row .col.push-s10 {
  left: 83.3333333333%;
}

.row .col.offset-s11 {
  margin-left: 91.6666666667%;
}

.row .col.pull-s11 {
  right: 91.6666666667%;
}

.row .col.push-s11 {
  left: 91.6666666667%;
}

.row .col.offset-s12 {
  margin-left: 100%;
}

.row .col.pull-s12 {
  right: 100%;
}

.row .col.push-s12 {
  left: 100%;
}

@media only screen and (min-width: 601px) {
  .row .col.m1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.offset-m1 {
    margin-left: 8.3333333333%;
  }
  .row .col.pull-m1 {
    right: 8.3333333333%;
  }
  .row .col.push-m1 {
    left: 8.3333333333%;
  }
  .row .col.offset-m2 {
    margin-left: 16.6666666667%;
  }
  .row .col.pull-m2 {
    right: 16.6666666667%;
  }
  .row .col.push-m2 {
    left: 16.6666666667%;
  }
  .row .col.offset-m3 {
    margin-left: 25%;
  }
  .row .col.pull-m3 {
    right: 25%;
  }
  .row .col.push-m3 {
    left: 25%;
  }
  .row .col.offset-m4 {
    margin-left: 33.3333333333%;
  }
  .row .col.pull-m4 {
    right: 33.3333333333%;
  }
  .row .col.push-m4 {
    left: 33.3333333333%;
  }
  .row .col.offset-m5 {
    margin-left: 41.6666666667%;
  }
  .row .col.pull-m5 {
    right: 41.6666666667%;
  }
  .row .col.push-m5 {
    left: 41.6666666667%;
  }
  .row .col.offset-m6 {
    margin-left: 50%;
  }
  .row .col.pull-m6 {
    right: 50%;
  }
  .row .col.push-m6 {
    left: 50%;
  }
  .row .col.offset-m7 {
    margin-left: 58.3333333333%;
  }
  .row .col.pull-m7 {
    right: 58.3333333333%;
  }
  .row .col.push-m7 {
    left: 58.3333333333%;
  }
  .row .col.offset-m8 {
    margin-left: 66.6666666667%;
  }
  .row .col.pull-m8 {
    right: 66.6666666667%;
  }
  .row .col.push-m8 {
    left: 66.6666666667%;
  }
  .row .col.offset-m9 {
    margin-left: 75%;
  }
  .row .col.pull-m9 {
    right: 75%;
  }
  .row .col.push-m9 {
    left: 75%;
  }
  .row .col.offset-m10 {
    margin-left: 83.3333333333%;
  }
  .row .col.pull-m10 {
    right: 83.3333333333%;
  }
  .row .col.push-m10 {
    left: 83.3333333333%;
  }
  .row .col.offset-m11 {
    margin-left: 91.6666666667%;
  }
  .row .col.pull-m11 {
    right: 91.6666666667%;
  }
  .row .col.push-m11 {
    left: 91.6666666667%;
  }
  .row .col.offset-m12 {
    margin-left: 100%;
  }
  .row .col.pull-m12 {
    right: 100%;
  }
  .row .col.push-m12 {
    left: 100%;
  }
}

@media only screen and (min-width: 993px) {
  .row .col.l1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.offset-l1 {
    margin-left: 8.3333333333%;
  }
  .row .col.pull-l1 {
    right: 8.3333333333%;
  }
  .row .col.push-l1 {
    left: 8.3333333333%;
  }
  .row .col.offset-l2 {
    margin-left: 16.6666666667%;
  }
  .row .col.pull-l2 {
    right: 16.6666666667%;
  }
  .row .col.push-l2 {
    left: 16.6666666667%;
  }
  .row .col.offset-l3 {
    margin-left: 25%;
  }
  .row .col.pull-l3 {
    right: 25%;
  }
  .row .col.push-l3 {
    left: 25%;
  }
  .row .col.offset-l4 {
    margin-left: 33.3333333333%;
  }
  .row .col.pull-l4 {
    right: 33.3333333333%;
  }
  .row .col.push-l4 {
    left: 33.3333333333%;
  }
  .row .col.offset-l5 {
    margin-left: 41.6666666667%;
  }
  .row .col.pull-l5 {
    right: 41.6666666667%;
  }
  .row .col.push-l5 {
    left: 41.6666666667%;
  }
  .row .col.offset-l6 {
    margin-left: 50%;
  }
  .row .col.pull-l6 {
    right: 50%;
  }
  .row .col.push-l6 {
    left: 50%;
  }
  .row .col.offset-l7 {
    margin-left: 58.3333333333%;
  }
  .row .col.pull-l7 {
    right: 58.3333333333%;
  }
  .row .col.push-l7 {
    left: 58.3333333333%;
  }
  .row .col.offset-l8 {
    margin-left: 66.6666666667%;
  }
  .row .col.pull-l8 {
    right: 66.6666666667%;
  }
  .row .col.push-l8 {
    left: 66.6666666667%;
  }
  .row .col.offset-l9 {
    margin-left: 75%;
  }
  .row .col.pull-l9 {
    right: 75%;
  }
  .row .col.push-l9 {
    left: 75%;
  }
  .row .col.offset-l10 {
    margin-left: 83.3333333333%;
  }
  .row .col.pull-l10 {
    right: 83.3333333333%;
  }
  .row .col.push-l10 {
    left: 83.3333333333%;
  }
  .row .col.offset-l11 {
    margin-left: 91.6666666667%;
  }
  .row .col.pull-l11 {
    right: 91.6666666667%;
  }
  .row .col.push-l11 {
    left: 91.6666666667%;
  }
  .row .col.offset-l12 {
    margin-left: 100%;
  }
  .row .col.pull-l12 {
    right: 100%;
  }
  .row .col.push-l12 {
    left: 100%;
  }
}

@media only screen and (min-width: 1201px) {
  .row .col.xl1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.offset-xl1 {
    margin-left: 8.3333333333%;
  }
  .row .col.pull-xl1 {
    right: 8.3333333333%;
  }
  .row .col.push-xl1 {
    left: 8.3333333333%;
  }
  .row .col.offset-xl2 {
    margin-left: 16.6666666667%;
  }
  .row .col.pull-xl2 {
    right: 16.6666666667%;
  }
  .row .col.push-xl2 {
    left: 16.6666666667%;
  }
  .row .col.offset-xl3 {
    margin-left: 25%;
  }
  .row .col.pull-xl3 {
    right: 25%;
  }
  .row .col.push-xl3 {
    left: 25%;
  }
  .row .col.offset-xl4 {
    margin-left: 33.3333333333%;
  }
  .row .col.pull-xl4 {
    right: 33.3333333333%;
  }
  .row .col.push-xl4 {
    left: 33.3333333333%;
  }
  .row .col.offset-xl5 {
    margin-left: 41.6666666667%;
  }
  .row .col.pull-xl5 {
    right: 41.6666666667%;
  }
  .row .col.push-xl5 {
    left: 41.6666666667%;
  }
  .row .col.offset-xl6 {
    margin-left: 50%;
  }
  .row .col.pull-xl6 {
    right: 50%;
  }
  .row .col.push-xl6 {
    left: 50%;
  }
  .row .col.offset-xl7 {
    margin-left: 58.3333333333%;
  }
  .row .col.pull-xl7 {
    right: 58.3333333333%;
  }
  .row .col.push-xl7 {
    left: 58.3333333333%;
  }
  .row .col.offset-xl8 {
    margin-left: 66.6666666667%;
  }
  .row .col.pull-xl8 {
    right: 66.6666666667%;
  }
  .row .col.push-xl8 {
    left: 66.6666666667%;
  }
  .row .col.offset-xl9 {
    margin-left: 75%;
  }
  .row .col.pull-xl9 {
    right: 75%;
  }
  .row .col.push-xl9 {
    left: 75%;
  }
  .row .col.offset-xl10 {
    margin-left: 83.3333333333%;
  }
  .row .col.pull-xl10 {
    right: 83.3333333333%;
  }
  .row .col.push-xl10 {
    left: 83.3333333333%;
  }
  .row .col.offset-xl11 {
    margin-left: 91.6666666667%;
  }
  .row .col.pull-xl11 {
    right: 91.6666666667%;
  }
  .row .col.push-xl11 {
    left: 91.6666666667%;
  }
  .row .col.offset-xl12 {
    margin-left: 100%;
  }
  .row .col.pull-xl12 {
    right: 100%;
  }
  .row .col.push-xl12 {
    left: 100%;
  }
}

nav {
  color: #fff;
  background-color: #ee6e73;
  width: 100%;
  height: 56px;
  line-height: 56px;
}

nav.nav-extended {
  height: auto;
}

nav.nav-extended .nav-wrapper {
  min-height: 56px;
  height: auto;
}

nav.nav-extended .nav-content {
  position: relative;
  line-height: normal;
}

nav a {
  color: #fff;
}

nav i,
nav [class^="mdi-"], nav [class*="mdi-"],
nav i.material-icons {
  display: block;
  font-size: 24px;
  height: 56px;
  line-height: 56px;
}

nav .nav-wrapper {
  position: relative;
  height: 100%;
}

@media only screen and (min-width: 993px) {
  nav a.sidenav-trigger {
    display: none;
  }
}

nav .sidenav-trigger {
  float: left;
  position: relative;
  z-index: 1;
  height: 56px;
  margin: 0 18px;
}

nav .sidenav-trigger i {
  height: 56px;
  line-height: 56px;
}

nav .brand-logo {
  position: absolute;
  color: #fff;
  display: inline-block;
  font-size: 2.1rem;
  padding: 0;
}

nav .brand-logo.center {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media only screen and (max-width: 992px) {
  nav .brand-logo {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  nav .brand-logo.left, nav .brand-logo.right {
    padding: 0;
    -webkit-transform: none;
            transform: none;
  }
  nav .brand-logo.left {
    left: 0.5rem;
  }
  nav .brand-logo.right {
    right: 0.5rem;
    left: auto;
  }
}

nav .brand-logo.right {
  right: 0.5rem;
  padding: 0;
}

nav .brand-logo i,
nav .brand-logo [class^="mdi-"], nav .brand-logo [class*="mdi-"],
nav .brand-logo i.material-icons {
  float: left;
  margin-right: 15px;
}

nav .nav-title {
  display: inline-block;
  font-size: 32px;
  padding: 28px 0;
}

nav ul {
  margin: 0;
}

nav ul li {
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  float: left;
  padding: 0;
}

nav ul li.active {
  background-color: rgba(0, 0, 0, 0.1);
}

nav ul a {
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  font-size: 1rem;
  color: #fff;
  display: block;
  padding: 0 15px;
  cursor: pointer;
}

nav ul a.btn, nav ul a.btn-large, nav ul a.btn-small, nav ul a.btn-large, nav ul a.btn-flat, nav ul a.btn-floating {
  margin-top: -2px;
  margin-left: 15px;
  margin-right: 15px;
}

nav ul a.btn > .material-icons, nav ul a.btn-large > .material-icons, nav ul a.btn-small > .material-icons, nav ul a.btn-large > .material-icons, nav ul a.btn-flat > .material-icons, nav ul a.btn-floating > .material-icons {
  height: inherit;
  line-height: inherit;
}

nav ul a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

nav ul.left {
  float: left;
}

nav form {
  height: 100%;
}

nav .input-field {
  margin: 0;
  height: 100%;
}

nav .input-field input {
  height: 100%;
  font-size: 1.2rem;
  border: none;
  padding-left: 2rem;
}

nav .input-field input:focus, nav .input-field input[type=text]:valid, nav .input-field input[type=password]:valid, nav .input-field input[type=email]:valid, nav .input-field input[type=url]:valid, nav .input-field input[type=date]:valid {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

nav .input-field label {
  top: 0;
  left: 0;
}

nav .input-field label i {
  color: rgba(255, 255, 255, 0.7);
  -webkit-transition: color .3s;
  transition: color .3s;
}

nav .input-field label.active i {
  color: #fff;
}

.navbar-fixed {
  position: relative;
  height: 56px;
  z-index: 997;
}

.navbar-fixed nav {
  position: fixed;
}

@media only screen and (min-width: 601px) {
  nav.nav-extended .nav-wrapper {
    min-height: 64px;
  }
  nav, nav .nav-wrapper i, nav a.sidenav-trigger, nav a.sidenav-trigger i {
    height: 64px;
    line-height: 64px;
  }
  .navbar-fixed {
    height: 64px;
  }
}

a {
  text-decoration: none;
}

html {
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.87);
}

@media only screen and (min-width: 0) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) {
  html {
    font-size: 14.5px;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    font-size: 15px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  line-height: 1.3;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-weight: inherit;
}

h1 {
  font-size: 4.2rem;
  line-height: 110%;
  margin: 2.8rem 0 1.68rem 0;
}

h2 {
  font-size: 3.56rem;
  line-height: 110%;
  margin: 2.3733333333rem 0 1.424rem 0;
}

h3 {
  font-size: 2.92rem;
  line-height: 110%;
  margin: 1.9466666667rem 0 1.168rem 0;
}

h4 {
  font-size: 2.28rem;
  line-height: 110%;
  margin: 1.52rem 0 0.912rem 0;
}

h5 {
  font-size: 1.64rem;
  line-height: 110%;
  margin: 1.0933333333rem 0 0.656rem 0;
}

h6 {
  font-size: 1.15rem;
  line-height: 110%;
  margin: 0.7666666667rem 0 0.46rem 0;
}


strong {
  font-weight: 500;
}


.light {
  font-weight: 300;
}

.thin {
  font-weight: 200;
}



.btn, .btn-large, .btn-small,
.btn-flat {
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.btn.disabled, .disabled.btn-large, .disabled.btn-small,
.btn-floating.disabled,
.btn-large.disabled,
.btn-small.disabled,
.btn-flat.disabled,
.btn:disabled,
.btn-large:disabled,
.btn-small:disabled,
.btn-floating:disabled,
.btn-large:disabled,
.btn-small:disabled,
.btn-flat:disabled,
.btn[disabled],
.btn-large[disabled],
.btn-small[disabled],
.btn-floating[disabled],
.btn-large[disabled],
.btn-small[disabled],
.btn-flat[disabled] {
  pointer-events: none;
  background-color: #DFDFDF !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #9F9F9F !important;
  cursor: default;
}

.btn.disabled:hover, .disabled.btn-large:hover, .disabled.btn-small:hover,
.btn-floating.disabled:hover,
.btn-large.disabled:hover,
.btn-small.disabled:hover,
.btn-flat.disabled:hover,
.btn:disabled:hover,
.btn-large:disabled:hover,
.btn-small:disabled:hover,
.btn-floating:disabled:hover,
.btn-large:disabled:hover,
.btn-small:disabled:hover,
.btn-flat:disabled:hover,
.btn[disabled]:hover,
.btn-large[disabled]:hover,
.btn-small[disabled]:hover,
.btn-floating[disabled]:hover,
.btn-large[disabled]:hover,
.btn-small[disabled]:hover,
.btn-flat[disabled]:hover {
  background-color: #DFDFDF !important;
  color: #9F9F9F !important;
}

.btn, .btn-large, .btn-small,
.btn-floating,
.btn-large,
.btn-small,
.btn-flat {
  font-size: 14px;
  outline: 0;
}

.btn i, .btn-large i, .btn-small i,
.btn-floating i,
.btn-large i,
.btn-small i,
.btn-flat i {
  font-size: 1.3rem;
  line-height: inherit;
}

.btn:focus, .btn-large:focus, .btn-small:focus,
.btn-floating:focus {
  background-color: #1d7d74;
}

.btn, .btn-large, .btn-small {
  text-decoration: none;
  color: #fff;
  background-color: #26a69a;
  text-align: center;
  letter-spacing: .5px;
  -webkit-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out;
  cursor: pointer;
}

.btn:hover, .btn-large:hover, .btn-small:hover {
  background-color: #2bbbad;
}

.btn-floating {
  display: inline-block;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  background-color: #26a69a;
  border-radius: 50%;
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  cursor: pointer;
  vertical-align: middle;
}

.btn-floating:hover {
  background-color: #26a69a;
}

.btn-floating:before {
  border-radius: 0;
}

.btn-floating.btn-large {
  width: 56px;
  height: 56px;
  padding: 0;
}

.btn-floating.btn-large.halfway-fab {
  bottom: -28px;
}

.btn-floating.btn-large i {
  line-height: 56px;
}

.btn-floating.btn-small {
  width: 32.4px;
  height: 32.4px;
}

.btn-floating.btn-small.halfway-fab {
  bottom: -16.2px;
}

.btn-floating.btn-small i {
  line-height: 32.4px;
}

.btn-floating.halfway-fab {
  position: absolute;
  right: 24px;
  bottom: -20px;
}

.btn-floating.halfway-fab.left {
  right: auto;
  left: 24px;
}

.btn-floating i {
  width: inherit;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-size: 1.6rem;
  line-height: 40px;
}

button.btn-floating {
  border: none;
}

.fixed-action-btn {
  position: fixed;
  right: 23px;
  bottom: 23px;
  padding-top: 15px;
  margin-bottom: 0;
  z-index: 997;
}

.fixed-action-btn.active ul {
  visibility: visible;
}

.fixed-action-btn.direction-left, .fixed-action-btn.direction-right {
  padding: 0 0 0 15px;
}

.fixed-action-btn.direction-left ul, .fixed-action-btn.direction-right ul {
  text-align: right;
  right: 64px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 100%;
  left: auto;
  /*width 100% only goes to width of button container */
  width: 500px;
}

.fixed-action-btn.direction-left ul li, .fixed-action-btn.direction-right ul li {
  display: inline-block;
  margin: 7.5px 15px 0 0;
}

.fixed-action-btn.direction-right {
  padding: 0 15px 0 0;
}

.fixed-action-btn.direction-right ul {
  text-align: left;
  direction: rtl;
  left: 64px;
  right: auto;
}



.btn-large {
  height: 54px;
  line-height: 54px;
  font-size: 15px;
  padding: 0 28px;
}


.sidenav .collapsible,
.sidenav.fixed .collapsible {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.sidenav .collapsible li,
.sidenav.fixed .collapsible li {
  padding: 0;
}

.sidenav .collapsible-header,
.sidenav.fixed .collapsible-header {
  background-color: transparent;
  border: none;
  line-height: inherit;
  height: inherit;
  padding: 0 16px;
}

.sidenav .collapsible-header:hover,
.sidenav.fixed .collapsible-header:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.sidenav .collapsible-header i,
.sidenav.fixed .collapsible-header i {
  line-height: inherit;
}

.sidenav .collapsible-body,
.sidenav.fixed .collapsible-body {
  border: 0;
  background-color: #fff;
}

.sidenav .collapsible-body li a,
.sidenav.fixed .collapsible-body li a {
  padding: 0 23.5px 0 31px;
}

.collapsible.popout {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}


button:focus {
  outline: none;
  background-color: #2ab7a9;
}

.nav-wrapper .input-field input[type=search] {
  height: inherit;
  padding-left: 4rem;
  width: calc(100% - 4rem);
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}



.sidenav {
  position: fixed;
  width: 300px;
  left: 0;
  top: 0;
  margin: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  height: 100%;
  height: calc(100% + 60px);
  height: -moz-calc(100%);
  padding-bottom: 60px;
  background-color: #fff;
  z-index: 999;
  overflow-y: auto;
  will-change: transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateX(-105%);
          transform: translateX(-105%);
}

.sidenav.right-aligned {
  right: 0;
  -webkit-transform: translateX(105%);
          transform: translateX(105%);
  left: auto;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.sidenav .collapsible {
  margin: 0;
}

.sidenav li {
  float: none;
  line-height: 48px;
}

.sidenav li.active {
  background-color: rgba(0, 0, 0, 0.05);
}

.sidenav li > a {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
}

.sidenav li > a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.sidenav li > a.btn, .sidenav li > a.btn-large, .sidenav li > a.btn-small, .sidenav li > a.btn-large, .sidenav li > a.btn-flat, .sidenav li > a.btn-floating {
  margin: 10px 15px;
}

.sidenav li > a.btn, .sidenav li > a.btn-large, .sidenav li > a.btn-small, .sidenav li > a.btn-large, .sidenav li > a.btn-floating {
  color: #fff;
}

.sidenav li > a.btn-flat {
  color: #343434;
}

.sidenav li > a.btn:hover, .sidenav li > a.btn-large:hover, .sidenav li > a.btn-small:hover, .sidenav li > a.btn-large:hover {
  background-color: #2bbbad;
}

.sidenav li > a.btn-floating:hover {
  background-color: #26a69a;
}

.sidenav li > a > i,
.sidenav li > a > [class^="mdi-"], .sidenav li > a li > a > [class*="mdi-"],
.sidenav li > a > i.material-icons {
  float: left;
  height: 48px;
  line-height: 48px;
  margin: 0 32px 0 0;
  width: 24px;
  color: rgba(0, 0, 0, 0.54);
}

.sidenav .divider {
  margin: 8px 0 0 0;
}

.sidenav .subheader {
  cursor: initial;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
}

.sidenav .subheader:hover {
  background-color: transparent;
}

.sidenav .user-view {
  position: relative;
  padding: 32px 32px 0;
  margin-bottom: 8px;
}

.sidenav .user-view > a {
  height: auto;
  padding: 0;
}

.sidenav .user-view > a:hover {
  background-color: transparent;
}

.sidenav .user-view .background {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.sidenav .user-view .circle, .sidenav .user-view .name, .sidenav .user-view .email {
  display: block;
}

.sidenav .user-view .circle {
  height: 64px;
  width: 64px;
}

.sidenav .user-view .name,
.sidenav .user-view .email {
  font-size: 14px;
  line-height: 24px;
}

.sidenav .user-view .name {
  margin-top: 16px;
  font-weight: 500;
}

.sidenav .user-view .email {
  padding-bottom: 16px;
  font-weight: 400;
}

.sidenav.sidenav-fixed {
  left: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  position: fixed;
}

.sidenav.sidenav-fixed.right-aligned {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 992px) {
  .sidenav.sidenav-fixed {
    -webkit-transform: translateX(-105%);
            transform: translateX(-105%);
  }
  .sidenav.sidenav-fixed.right-aligned {
    -webkit-transform: translateX(105%);
            transform: translateX(105%);
  }
  .sidenav > a {
    padding: 0 16px;
  }
  .sidenav .user-view {
    padding: 16px 16px 0;
  }
}

.sidenav .collapsible-body > ul:not(.collapsible) > li.active,
.sidenav.sidenav-fixed .collapsible-body > ul:not(.collapsible) > li.active {
  background-color: #ee6e73;
}

.sidenav .collapsible-body > ul:not(.collapsible) > li.active a,
.sidenav.sidenav-fixed .collapsible-body > ul:not(.collapsible) > li.active a {
  color: #fff;
}

.sidenav .collapsible-body {
  padding: 0;
}

.sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 997;
  display: none;
}



/* Your code */

.hover-test {
  background-color: #000;
}

p {
  font-family: "Source Sans Pro",Sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.container {
  width: 90%;
}

.brand-logo.nav img {
  padding: 5px 0;
}


/* Mobile devices */
@media only screen and (max-width: 414px) {

  .brand-logo img {
    width: 200px;
  }

  h1 {
    font-size: 3.6rem;
  }

  h2 {
    font-size: 2.7rem;
  }

  h3 {
    font-size: 28px !important;
  }

  p {
    font-size: 17px;
  }

  .video-content li {
    font-size: 16px !important;
  }

  .phillies-camera-operator img {
    height: 200px !important;
    width: 200px !important;
  }

  .dabrian-laptop img {
    height: 150px !important;
    width: 250px !important;
  }

  footer {
    text-align: center;
  }

}

/* Hover over nav links */
nav ul a:hover {
  color: yellow;
  background-color: transparent;
}

nav ul a:focus {
  color: yellow;
  background-color: transparent;
}


.content {
  /* margin-top: 100px; If you use fixed navbar */
  padding-bottom:  40px; /* Without fixed navbar */
}


/* Hero Image on home page */
.hero {
  background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(images/hero-image.jpg);
  background-size: cover;
  background-position: center;
  min-height: 500px;
}

/* Content on Hero Image */
.hero-content {
  color: #fff;
  padding-top: 100px;
}

.hero-content h1 {
  font-weight: 800;
}

.hero-content p {
  font-weight: 400;
}

.video {
  padding: 50px;
}

/* Page Title */
.title {
  color: #1B5E20;
  font-weight: bold;
}

.skills {
  text-align: center;
}

/* About Page */
@media only screen and (min-width: 993px) {
.row .col.offset-l2 {
    margin-left: 12.666667%;
}
}

.about-me-desktop .video-clip {
  padding: 40px !important;
}

.image {
  padding: 40px !important;
  text-align: right;
}

.video-content  h3 {
  font-weight: 400;
  font-size: 40px;
}

.alexa-content h3 {
  font-weight: 400;
  font-size: 40px;
}

.website-title h3 {
  font-weight: 400;
  font-size: 40px;
}

.video-content  li {
  font-size: 20px;
}

/* Images for Websites and Alexa */
.website-image {
  text-align: center;
}

.alexa-image {
  text-align: center;
}

.flag-image {
  text-align: center;
}

/* Videos for mobile (Videos page) */



@media only screen and (min-width: 1025px) {

  .about-me-mobile {
    display: none;
  }

  .title-mobile {
    display: none;
  }

}

/* On Mobile */
@media only screen and (max-width: 1024px) {

  .about-me-desktop {
    display: none;
  }

  .title-desktop {
    display: none;
  }

  .video-image {
    margin: 0 auto;
    text-align: center;
  }



  .video .row {
      text-align: center;
  }

  .video-content {
    text-align: center;
  }

}

/* Video Clips on Tablets */
@media only screen and (min-width: 769px) and (max-width: 1024px) {

  iframe {
   width: 700px;
   height: 350px;
 }

 .video-clip iframe {
  width: 700px;
  height: 400px;
}

.Videos .row .col.l4 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.phillies-camera-operator img {
  height: 400px;
  width: 400px;
}

.dabrian-laptop img {
  height: 350px;
  width: 600px;
}

}

@media only screen and (min-width: 415px) and (max-width: 768px) {

  iframe {
   width: 600px;
   height: 340px;
 }

 .video-clip iframe {
  width: 600px;
  height: 340px;
}

.phillies-camera-operator img {
  height: 300px;
  width: 300px;
}

.dabrian-laptop img {
  height: 350px;
  width: 600px;
}

}

@media only screen and (max-width: 415px) {

 .video-clip iframe {
  width: 260px;
  height: 150px;
}


}


/* Padding on Websites, Alexa Skills, and Videos */
.website, .video, .alexa {
  padding: 50px 0px !important;
}

/* Single image of website per row on iPad */
@media only screen and (max-width: 768px) {
  .website {
    width: 100% !important;
  }

}

/* Footer */

a.white-text.text-lighten-3:hover {
    color: yellow !important;
}

a.white-text.text-lighten-3:focus {
    color: yellow !important;
}

.privacy-policy a:hover {
    color: yellow !important;
}

.privacy-policy a:focus {
    color: yellow !important;
}

.font-awesome-icon {
  font-size: 32px;
}

.contact-icons li {
  display: inline;
  margin-right: 20px;
}

.social-media-icons li {
  display: inline;
  margin-right: 20px;
}

.contact-icons-mobile li {
  display: inline;
  float: left;
}

ul.contact-icons-mobile li {
   margin-right: -40px;
}

.social-media-icons-mobile li {
  display: inline;
  float: left;
}

ul.social-media-icons-mobile li {
    margin-right: -40px;
}

/* Contact Me */
.contact.contact-icons {
  text-align: center;
}

.contact.social-media-icons {
  text-align: center;
}

.icons {
  font-size: 100px;
  text-align: center;
}

.contact-me {
  color: #283593;
}

.facebook {
  color: #3b5998;
}

.linkedin {
  color: #0e76a8;
}

.instagram {
  color: #fb3958;
}

.youtube {
  color: #c4302b;
}

.tiktok {
  color: #010101;
}

/* Contact CTA Box */
.contact-cta {
  padding: 40px;
}

footer h5 {
  font-size: 1.85rem;
}

footer li {
  font-size: 16px;
}

/* Blue footer with copyright */
.footer-copyright {
  padding: 0px !important;
}

/* CTA Button */
.btn-large:hover {
  background-color: #3d9140 !important;
}

/* Hamburger menu */
.sidenav {
  background-color: #1B5E20;
}

.sidenav li > a {
  color: #fff;
}

.sidenav li > a:hover {
  color: yellow;
  background-color: transparent;
}

.sidenav li > a:focus {
  color: yellow;
  background-color: transparent;
}

.contact-icons-mobile li > a, .social-media-icons-mobile li > a {
  font-size: 32px;
}

/* Flag image on iPad Pro */
@media only screen and (max-width: 1024px) and (min-width: 993px) {

  .AlexaSkills .row .col.l3  {
    width: 50%;
  }

  .AlexaSkillsCanada .row .col.l3  {
    width: 50%;
  }

  .AlexaSkillsUnitedKingdom .row .col.l3  {
    width: 50%;
  }

  .AlexaSkillsAustralia .row .col.l3  {
    width: 50%;
  }

  .AlexaSkillsMexico .row .col.l3  {
    width: 50%;
  }

  .AlexaSkillsSpain .row .col.l3  {
    width: 50%;
  }

  .AlexaSkillsBrazil .row .col.l3  {
    width: 50%;
  }

  .AlexaSkillsFrance .row .col.l3  {
    width: 50%;
  }

  .AlexaSkillsGermany .row .col.l3  {
    width: 50%;
  }

  .AlexaSkillsItaly .row .col.l3  {
    width: 50%;
  }

  .AlexaSkillsJapan .row .col.l3  {
    width: 50%;
  }

  .AlexaSkillsIndia .row .col.l3  {
    width: 50%;
  }

}


img.title-mobile {
    padding-top: 8px;
}
